//

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main/index';
import ReportLayout from '../layouts/report';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import PWAOfflineReportsEdit from 'src/pages/pwa-offline-report/PWAOfflineReportsEdit';

// ----------------------------------------------------------------------

// eslint-disable-next-line
const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <MainLayout />
        </GuestGuard>
      ),
      children: [
        { path: '/', element: <WelcomeScreen /> },
        { path: 'login', element: <LogInScreen /> },
      ],
    },

    // report routes
    {
      path: 'report',
      element: (
        <AuthGuard>
          <ReportLayout />
        </AuthGuard>
      ),
      children: [
        // PWA report routes ...
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'select-report', element: <PWAReportScreen /> },
        { path: 'new-report', element: <PWANewReportScreen /> },
        { path: 'new-report/:id', element: <PWARanchReportDetail /> },
        { path: 'new-scout-inventory', element: <PWAScoutInventory /> },
        //
        { path: 'existing-reports', element: <PWAExistingReports /> },
        {
          path: 'existing-reports/edit/:id',
          element: <PWAExistingReportsEdit />,
        },
        {
          path: 'offline-reports/edit/:id',
          element: <PWAOfflineReportsEdit />,
        },
      ],
    },

    // { path: '*', element: <NotFound />  },
  ]);
}

//
// const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Authentication
const WelcomeScreen = Loadable(
  lazy(() => import('../pages/authentication/Welcome'))
);
const LogInScreen = Loadable(
  lazy(() => import('../pages/authentication/Login'))
);
// pwa report
const PWAReportScreen = Loadable(
  lazy(() => import('../pages/pwa-report/PWAReportScreen'))
);
const PWANewReportScreen = Loadable(
  lazy(() => import('../pages/pwa-report/PWANewReportScreen'))
);
const PWARanchReportDetail = Loadable(
  lazy(() => import('../pages/pwa-report/PWARanchReportDetail'))
);
const PWAScoutInventory = Loadable(
  lazy(() => import('../pages/scoutInventory/index'))
);
const PWAExistingReports = Loadable(
  lazy(() => import('../pages/pwa-existing-report/PWAExistingReports'))
);
const PWAExistingReportsEdit = Loadable(
  lazy(() => import('../pages/pwa-existing-report/PWAExistingReportsEdit'))
);
