//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// indexDB
import { updateIndexDBRecord } from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG } from '../../indexDB/configDB';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  commodities: [],
  error: null,
};

const slice = createSlice({
  name: 'commodities',
  initialState,
  reducers: {
    // START LOADING
    startLoading(commodities) {
      commodities.isLoading = true;
      commodities.commodities = [];
    },

    // HAS ERROR
    hasError(commodities, action) {
      commodities.isLoading = false;
      commodities.error = action.payload;
      commodities.commodities = [];
    },

    // GET
    getCommoditiesSuccess(commodities, action) {
      commodities.isLoading = false;
      commodities.commodities = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPWACommodities() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/crop-commodities');
      if (response?.data?.status === 1) {
        if (Array.isArray(response.data?.data)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.cropCommodities.storeObject,
            response.data.data
          );

          dispatch(slice.actions.getCommoditiesSuccess(response.data.data));
        } else {
          console.error('Invalid API response of v1/crop-commodities');
        }
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
