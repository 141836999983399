//

import {
  isBrowser,
  isMobile,
  isAndroid,
  isIPhone13,
  isIOS,
} from 'react-device-detect';

// --------------------------------------------

export default function navigateMapTo(lat, lng) {
  if (!lat || !lng) return;

  if (isBrowser) {
    // if browser => open google maps on browser
    const win = window.open(
      'http://maps.google.com?q=' + lat + ',' + lng + '&ll=' + lat + ',' + lng,
      '_blank'
    );
    win.focus();
  } else if (isMobile && (isIPhone13 || isIOS)) {
    // if iphone => open maps app
    window.location =
      'maps://maps.apple.com?q=' + lat + ',' + lng + '&ll=' + lat + ',' + lng;
  } else if (isMobile && isAndroid) {
    // if android => open maps app
    window.location =
      'https://maps.google.com?q=' + lat + ',' + lng + '&ll=' + lat + ',' + lng;
  } else {
    // default => open google maps on browser
    window.location =
      'https://maps.google.com?q=' + lat + ',' + lng + '&ll=' + lat + ',' + lng;
  }
}
