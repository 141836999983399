//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// indexDB
import { updateIndexDBRecord } from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG } from '../../indexDB/configDB';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  peoples: [],
  error: null,
};

const slice = createSlice({
  name: 'peoples',
  initialState,
  reducers: {
    // START LOADING
    startLoading(peoples) {
      peoples.isLoading = true;
      peoples.peoples = {};
    },

    // HAS ERROR
    hasError(peoples, action) {
      peoples.isLoading = false;
      peoples.error = action.payload;
      peoples.peoples = {};
    },

    // GET
    getPeoplesSuccess(peoples, action) {
      peoples.isLoading = false;
      peoples.peoples = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllPeoples() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/peoples');
      if (response?.data?.status === 1) {
        if (Array.isArray(response.data?.data?.peoples)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.peoples.storeObject,
            response.data.data.peoples
          );
        } else {
          console.error('Invalid API response of v1/peoples => customer');
        }
        if (Array.isArray(response.data?.data?.prospect_peoples)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.prospectPeoples.storeObject,
            response.data.data.prospect_peoples
          );
        } else {
          console.error('Invalid API response of v1/peoples => prospect peoples');
        }

        dispatch(slice.actions.getPeoplesSuccess(response.data.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
