//

import React, { useState, useEffect } from 'react';
import moment from 'moment';
// @mui
import { Box, Card, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function CurrentRanchDetail(props) {
  const { ranchReport } = props;

  const [ranchRecord, setRanchRecord] = useState({});

  useEffect(() => {
    if (ranchReport) {
      setRanchRecord(ranchReport);
    } else {
      setTimeout(() => {
        const ranchRecord = JSON.parse(
          window.localStorage.getItem('ranchReport')
        );
        setRanchRecord(ranchRecord);
      }, 200);
    }
  }, [ranchReport]);

  return (
    <Box sx={{ pt: '1rem' }}>
      <Card
        sx={{
          backgroundColor: '#E8E8E8',
          p: 2,
          borderRadius: 1,
        }}
      >
        <Typography variant="subtitle2">
          {ranchRecord?.grower?.label ? ranchRecord.grower.label : ''}
        </Typography>

        <Typography component="div" sx={{ display: 'flex' }}>
          <Typography variant="caption" color="text.secondary">
            Date:
          </Typography>
          <Typography variant="caption">
            &nbsp;
            {ranchRecord?.date
              ? moment(ranchRecord.date).format('DD MMM YYYY')
              : ''}
          </Typography>
        </Typography>

        <Typography component="div" sx={{ display: 'flex' }}>
          <Typography variant="caption" color="text.secondary">
            Ranch:
          </Typography>
          <Typography variant="caption">
            &nbsp;{ranchRecord?.ranch?.label ? ranchRecord.ranch.label : ''}
          </Typography>
        </Typography>

        <Typography component="div" sx={{ display: 'flex' }}>
          <Typography variant="caption" color="text.secondary">
            Ranch Blocks:
          </Typography>
          <Typography variant="caption">
            &nbsp;
            {ranchRecord?.ranchBlock
              ? ranchRecord.ranchBlock?.map((block) => block.label)?.join(', ')
              : ''}
          </Typography>
        </Typography>

        <Typography component="div" sx={{ display: 'flex' }}>
          <Typography variant="caption" color="text.secondary">
            Commodities:
          </Typography>
          <Typography variant="caption">
            &nbsp;
            {ranchRecord?.commodity
              ? ranchRecord.commodity
                  ?.map((commo) => commo.crop_commodity_name)
                  ?.join(', ')
              : ''}
          </Typography>
        </Typography>
      </Card>
    </Box>
  );
}
