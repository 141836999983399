import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Webcam from 'react-webcam';
import Slider from 'react-slick';
import { useGeolocated } from 'react-geolocated';
// mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Stack,
  Button,
  Card,
  TextField,
  alpha,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  ListItem,
  DialogActions,
  Container,
  Alert,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// redux
import { useSelector } from '../../redux/store';
// components
import { PATH_REPORT } from '../../routes/paths';
import CancelConfirmationModal from '../CancelConfirmationModal';
import Image from '../../components/Image';
import LightboxModal from '../../components/LightboxModal';
// indexDB
import {
  getAllIndexDBRecords,
  updateIndexDBRecord,
} from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG, openDatabase } from '../../indexDB/configDB';
//
import navigateMapTo from '../../utils/geoLocation';
import {
  CameraFillIcon,
  CloseFillIcon,
  ImageIcon,
  LinkIcon,
  LocationOnIcon,
  OutlineCheckOneIcon,
  RotateIcon,
  RoundedNextIcon,
  RoundedPreviousIcon,
} from '../../assets';
import CurrentRanchDetail from '../pwa-report/CurrentRanchDetailAlert';

// ----------------------------------------------------------------------

const ContentWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  top: '2vh',
  paddingInline: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  marginBottom: theme.spacing(2),
  backgroundColor: alpha(theme.palette.grey[200], 0.94),
  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  borderRadius: theme.spacing(2),
  boxShadow: theme.customShadows.card,
  // maxHeight: '54vh',
  overflow: 'auto',
}));

// ----------------------------------------------------------------------

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function PWAOfflineReportsEdit() {
  const params = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const {
    /* data: submitReport, */ succMsg,
    errMsg,
    isLoading: isSubmitting,
  } = useSelector((state) => state.PWASubmitReport);

  const fileInput = useRef(null);

  // activeStep: possible values => 0, -1, -2 and 1 to n (number of categories) ...
  const [activeStep, setActiveStep] = useState(0);
  const [categories, setCatogories] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [categoryNote, setCategoryNote] = useState('');
  const [itemComment, setItemComment] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemObj, setItemObj] = useState([]);
  const [singleItemImages, setSingleItemImages] = useState([]);
  const [previewImageIndex, setPreviewImageIndex] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [locationPins, setLocationPins] = useState([]);
  // ticket #5914: deselect the default selected options when user selects any options manually
  // state to manage if we should remove the default selected options or not
  const [shouldRemoveDefaultOptions, setShouldRemoveDefaultOptions] =
    useState(false);

  // web cam
  const [isOpenWebCamModal, setIsOpenWebCamModal] = useState(false);

  // scout inventories
  const [reportsInventories, setReportsInventories] = useState([]);
  const [scoutInventoryComment, setScoutInventoryComment] = useState('');
  const [scoutInventoryReading, setScoutInventoryReading] = useState({});
  const [ranchRecord, setRanchRecord] = useState();
  const [offlineData, setOfflineData] = useState({});

  useEffect(() => {
    const setRecordsFromIndexDB = async () => {
      const db = await openDatabase();
      const record = await db.transaction('offlineSubmittedReports')
        .objectStore('offlineSubmittedReports')
        .get(params.id);

      const ranchReport = record?.offlineData;
      setOfflineData(record);
      setRanchRecord(record?.offlineData);
      setLocationPins([...record?.reportData?.location_pins])
      setCategoryNote(record?.reportData?.notes);
      setScoutInventoryComment(record?.reportData?.scout_inventory?.comment);

      const commodityQuestions = await getAllIndexDBRecords(
        INDEX_DB_CONFIG.questions.storeObject
      );

      if (
        ranchReport?.commodity?.length > 0 &&
        commodityQuestions?.length > 0
      ) {
        setQuestionData(commodityQuestions, ranchReport, record);
      } else {
        setCatogories([]);
        setQuestionList([]);
        setQuestionData([]);
      }

      // scout inventories
      const scoutInventories = await getAllIndexDBRecords(
        INDEX_DB_CONFIG.scoutInventories.storeObject
      );

      const tempAssociatedInventories = [];
      const ranchBlockIds = ranchReport?.ranchBlock?.map((el) => el.id);

      if (scoutInventories?.length) {
        for (let index = 0; index < scoutInventories.length; index++) {
          const element = scoutInventories[index];
          const {
            crop_location_block_id,
            crop_location_id,
            customer_id,
            expiration_date,
          } = element;

          const isNotExpired = moment(expiration_date).isSameOrAfter(moment());

          if (
            crop_location_block_id &&
            ranchBlockIds?.includes(crop_location_block_id) &&
            isNotExpired
          ) {
            tempAssociatedInventories.push(element);
          } else if (
            !crop_location_block_id &&
            crop_location_id &&
            crop_location_id === ranchReport?.ranch?.id &&
            isNotExpired
          ) {
            tempAssociatedInventories.push(element);
          } else if (
            !crop_location_block_id &&
            !crop_location_id &&
            customer_id &&
            customer_id === ranchReport?.grower?.id &&
            isNotExpired
          ) {
            tempAssociatedInventories.push(element);
          }
        }
      }
      const inventoryReadings = tempAssociatedInventories?.reduce((acc, response) => {
        record?.reportData?.scout_inventory?.readings?.forEach((res) => {
          if ((res?.scout_inventory_id || res?.id) === response?.id) {
            acc[response.id] = {
              ...response,
              scout_inventory_id: res?.id ? res.id : res?.scout_inventory_id,
              reading: res?.reading,
            };
          }
        });
        return acc;
      }, {});

      setScoutInventoryReading(inventoryReadings);
      setReportsInventories(tempAssociatedInventories);
    };

    setRecordsFromIndexDB();
    // eslint-disable-next-line
  }, []);

  const setQuestionData = (inspectionQuestions, ranchRecord, offlineData) => {
    if (inspectionQuestions?.length > 0) {
      // set question catogories ...
      const categories = inspectionQuestions?.map((ele) => ({
        id: ele?.scout_report_category?.id,
        itemId: ele?.id,
        category: ele?.scout_report_category?.name,
        categoryId: ele?.scout_report_category?.id,
      }));
      setCatogories(categories);

      // set question list ...
      const queArr = [];
      const commodityIds = ranchRecord?.commodity?.map(
        (ids) => ids?.crop_commodity_id
      );

      inspectionQuestions?.forEach((question) => {
        const filteredOptions = [];
        const commentAndImages = {
          comment: '',
          images: [],
        }

        question?.item_options?.forEach((opt) => {
          const temp = opt?.commodity_ids;
          if (temp?.some((val) => commodityIds?.includes(val))) {
            filteredOptions.push(opt);
            offlineData?.reportAttachments?.forEach((val) => {
              val?.answers?.forEach((res) => {
                const existingOption = filteredOptions.find(option => option.id === res?.question_item_attribute_id);
                if (existingOption) {
                  existingOption.is_default_selected = true;
                  existingOption.isSelected = true;
                  commentAndImages.comment = val?.comment;
                  commentAndImages.images = val?.captured_images;
                }
              })
            })
          }
        });

        const quesObj = {
          id: question.id,
          itemOptions: filteredOptions,
          categoryId: question?.scout_report_category?.id,
          categoryName: question?.scout_report_category?.name,
          captured_images: commentAndImages?.images,
          comment: commentAndImages?.comment,
        };
        queArr.push(quesObj);
      });
      setQuestionList(queArr);

      // here we will set the default selected items
      const tempSelectedItemObj = [];
      queArr?.forEach((que) => {
        const tempObj = {
          question_item_id: que?.id,
          comment: que?.comment,
          captured_images: que?.captured_images,
        };
        const tempAnswers = [];
        que?.itemOptions?.forEach((item) => {
          if (item?.is_default_selected && item?.isSelected) {
            tempAnswers.push({
              question_item_attribute_id: item?.id,
            });
          }
        });
        tempObj['answers'] = tempAnswers;
        tempObj['is_have_default_selected_options'] = Boolean(
          tempAnswers?.length
        );

        tempSelectedItemObj.push(tempObj);
      });
      setItemObj(tempSelectedItemObj);
    } else if (inspectionQuestions?.length === 0) {
      setCatogories([]);
      setQuestionList([]);
    }
  };

  const prevAmount = usePrevious({ succMsg, errMsg });
  useEffect(() => {
    if (succMsg && prevAmount && prevAmount.succMsg !== succMsg) {
      enqueueSnackbar(succMsg);

      navigate(PATH_REPORT.report.selectReport, { replace: true });
    }
    if (errMsg && prevAmount && prevAmount.errMsg !== errMsg) {
      enqueueSnackbar(errMsg, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [succMsg, errMsg]);

  const goToCatogory = (catId) => {
    if (catId === -1) {
      setActiveStep(-1);
    } else if (catId === -2) {
      setActiveStep(-2);
    } else {
      const stepIndex = questionList?.findIndex((que) => que.id === catId);
      if (stepIndex !== -1) setActiveStep(stepIndex + 1);

      const selectedItemData = itemObj?.find(
        (item) => item.question_item_id === catId
      );
      if (selectedItemData) {
        const selectedItems = selectedItemData?.answers?.map(
          (el) => el.question_item_attribute_id
        );
        setSelectedItems(selectedItems);
        setItemComment(selectedItemData?.comment);
        setSingleItemImages(selectedItemData?.captured_images);
        // set "shouldRemoveDefaultOptions" to true when user open the category details screen
        setShouldRemoveDefaultOptions(true);
      }
    }
  };

  const handleItemComment = (e) => {
    setItemComment(e.target.value);
  };

  const handleSelectCard = (event, id) => {
    const selectedCategory = questionList[activeStep - 1];
    const selectedCategoryItemObj = itemObj.find(
      (item) => item.question_item_id === selectedCategory.id
    );

    if (
      shouldRemoveDefaultOptions &&
      selectedCategoryItemObj?.is_have_default_selected_options
    ) {
      // #5914: when user selectes only the first option manually, we will remove the default selected options
      setSelectedItems([id]);
      setShouldRemoveDefaultOptions(false);
    } else {
      const selectedIndex = selectedItems.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedItems, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedItems.slice(1));
      } else if (selectedIndex === selectedItems.length - 1) {
        newSelected = newSelected.concat(selectedItems.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedItems.slice(0, selectedIndex),
          selectedItems.slice(selectedIndex + 1)
        );
      }

      setSelectedItems([...newSelected]);
    }
  };

  const checkSelecteditems = (itemId) => {
    const setColor =
      selectedItems.findIndex((selectedId) => selectedId === itemId) === -1
        ? 'background.paper'
        : 'primary.lighter';
    return setColor;
  };

  const chceckSelection = (cat) => {
    let isSelected = false;
    const record = itemObj.find((el) => el.question_item_id === cat.itemId);

    if (
      record?.answers?.length ||
      record?.captured_images?.length ||
      record?.comment?.length
    ) {
      isSelected = true;
    }

    return isSelected;
  };

  const handleCancelItems = () => {
    // remove new selected items for current visit ...
    let tempSelectedItems = selectedItems;

    selectedItems?.forEach((itemId) => {
      questionList[activeStep - 1].itemOptions?.forEach((itemOp) => {
        if (itemOp.id === itemId) {
          tempSelectedItems = tempSelectedItems?.filter(
            (tempItemId) => tempItemId !== itemId
          );
        }
      });
    });

    setSelectedItems(tempSelectedItems);

    // if user cancels that option, we should reset comment as well ...
    if (activeStep === -1) {
      setCategoryNote('');
    } else {
      setItemComment('');
    }
  };

  const handleNextItem = () => {
    if (activeStep === -1) {
      setActiveStep(0);
    } else if (activeStep === -2) {
      setActiveStep(0);
    } else {
      const selectedCategory = questionList[activeStep - 1];
      const answers = selectedItems?.map((id) => ({
        question_item_attribute_id: id,
      }));

      const itemObjTemp = {
        question_item_id: selectedCategory?.id,
        comment: itemComment,
        answers,
        captured_images: singleItemImages,
        is_have_default_selected_options: false,
      };

      if (
        answers?.length > 0 ||
        itemComment?.length > 0 ||
        singleItemImages?.length > 0
      ) {
        const itemTemp = itemObj?.findIndex(
          (el) => el.question_item_id === selectedCategory?.id
        );
        if (itemTemp === -1) {
          setItemObj([...itemObj, itemObjTemp]);
          setItemComment('');
        } else {
          const temp = itemObj.filter(
            (el) => el.question_item_id !== selectedCategory?.id
          );
          setItemObj([...temp, itemObjTemp]);
          setItemComment('');
        }
        setSingleItemImages([]);
      }

      setActiveStep(0);

      // selected items
      setSelectedItems([]);
    }
  };

  const handleBackbuttonClick = () => {
    setOpenConfirmationModal(true);
  };

  const handleSubmit = async () => {
    if (categoryNote?.length > 0 || itemObj?.length > 0) {
      const formattedDate = moment(ranchRecord?.date).format('DD-MM-YYYY');

      const postObj = {
        date: formattedDate,
        customer_id: ranchRecord?.grower?.id,
        crop_location_id: ranchRecord?.ranch?.id,
        crop_location_blocks: ranchRecord?.ranchBlock?.map((el) => el.id),
        uuid: offlineData?.uuid,
        notes: categoryNote,
        questions: itemObj?.map((obj) => {
          const data = {
            answers: obj.answers,
            comment: obj.comment,
            question_item_id: obj.question_item_id,
          };
          return data;
        }),
      };

      if (locationPins?.length) {
        postObj.location_pins = locationPins?.map((pin) => {
          const data = {
            latitude: pin?.latitude ?? '',
            longitude: pin?.longitude ?? '',
          };

          return data;
        });
      } else {
        postObj.location_pins = [];
      }

      // scout inventory reading ...
      const tempReading = [];
      for (const key in scoutInventoryReading) {
        if (Object.hasOwnProperty.call(scoutInventoryReading, key)) {
          const element = scoutInventoryReading[key];
          tempReading.push(element);
        }
      }

      postObj.scout_inventory = {
        readings: tempReading,
        comment: scoutInventoryComment,
      };

      const formData = new FormData();
      formData.append('data', JSON.stringify(postObj));
      formData.append('uuid', offlineData?.uuid);

      await updateIndexDBRecord(
        INDEX_DB_CONFIG.offlineSubmittedReports.storeObject,
        [
          {
            uuid: offlineData?.uuid,
            reportData: postObj,
            reportAttachments: itemObj,
            offlineData: ranchRecord,
          },
        ]
      );

      enqueueSnackbar(
        'Report updated successfully',
        { variant: 'success' }
      );
      navigate(PATH_REPORT.report.selectReport, { replace: true });
    } else {
      setErrorMessage(true);
    }
  };

  const previewImage = (imageIndex) => {
    setPreviewImageIndex(imageIndex);
  };

  const handleFileUploads = async (event) => {
    if (event?.target?.files?.length) {
      const fileSize = parseFloat(event.target.files[0].size / 1024).toFixed(2); // size in kb
      const storageLimitConfig = Number(
        window.localStorage.getItem('attachment_size') || 10
      );
      if (fileSize > 1024 * storageLimitConfig) {
        const sizeError = `The file must be less than ${storageLimitConfig}MB in size.`;
        enqueueSnackbar(sizeError, { variant: 'error' });
        return;
      }

      const { base64Data, fileName } = await toBase64(
        event.target.files[0]
      ).catch((e) => Error(e));

      setSingleItemImages([
        { name: fileName, data: base64Data },
        ...singleItemImages,
      ]);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result;
        const fileName = file.name;
        resolve({ base64Data, fileName });
      };
      reader.onerror = (error) => reject(error);
    });

  const handleRemoveImage = (index) => {
    const filteredImages = singleItemImages?.filter((el, ind) => index !== ind);
    setSingleItemImages(filteredImages);
  };

  const checkIfSubmitDisable = () => {
    let isDisabled = true;

    if (
      (itemObj?.length || categoryNote?.length)
    ) {
      isDisabled = false;
    }

    return isDisabled;
  };

  return (
    <Box>
      {ranchRecord && <CurrentRanchDetail ranchReport={ranchRecord} />}

      <Stack
        sx={{
          position: 'relative',
          top: '2vh',
          mb: 2,
        }}
        spacing={1}
      >
        <LocationPinComponent
          locationPins={locationPins}
          setLocationPins={setLocationPins}
        />
      </Stack>

      <ContentWrapper>
        <CategoriesContent
          activeStep={activeStep}
          errorMessage={errorMessage}
          categories={categories}
          goToCatogory={goToCatogory}
          categoryNote={categoryNote}
          chceckSelection={chceckSelection}
          questionList={questionList}
          fileInput={fileInput}
          setIsOpenWebCamModal={setIsOpenWebCamModal}
          singleItemImages={singleItemImages}
          previewImage={previewImage}
          handleRemoveImage={handleRemoveImage}
          checkSelecteditems={checkSelecteditems}
          handleSelectCard={handleSelectCard}
          setCategoryNote={setCategoryNote}
          handleItemComment={handleItemComment}
          handleNextItem={handleNextItem}
          handleBackbuttonClick={handleBackbuttonClick}
          itemComment={itemComment}
          reportsInventories={reportsInventories}
          scoutInventoryComment={scoutInventoryComment}
          setScoutInventoryComment={setScoutInventoryComment}
          scoutInventoryReading={scoutInventoryReading}
          setScoutInventoryReading={setScoutInventoryReading}
        />
      </ContentWrapper>

      <Box sx={{ pt: 5, mx: 1 }}>
        {activeStep === 0 ? (
          <Stack spacing={2}>
            <Button
              variant="contained"
              color="inherit"
              size="large"
              fullWidth
              onClick={handleBackbuttonClick}
              sx={{ backgroundColor: '#E8E8E8 !important' }}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="contained"
              size="large"
              onClick={() => handleSubmit()}
              fullWidth
              sx={{
                boxShadow: theme.customShadows.z24,
                backgroundColor: theme.palette.primary.dark,
                mx: 2,
                '&:disabled': {
                  border: `2px solid ${theme.palette.grey[400]}`,
                  color: theme.palette.grey[400],
                },
              }}
              disabled={checkIfSubmitDisable()}
            >
              Submit
            </LoadingButton>
          </Stack>
        ) : null}
      </Box>

      <Box sx={{ height: '24px' }} />

      {openConfirmationModal ? (
        <CancelConfirmationModal
          isOpen={openConfirmationModal}
          handleCancel={() => setOpenConfirmationModal(false)}
          handleSubmit={() => {
            if (activeStep === 0) {
              navigate(PATH_REPORT.report.selectReport);
            } else if (activeStep === -2) {
              // TODO: in progress
              setActiveStep(0);
              setOpenConfirmationModal(false);
            } else {
              setActiveStep(0);
              handleCancelItems();
              setSingleItemImages([]);
              setOpenConfirmationModal(false);
            }
          }}
        />
      ) : null}

      {isOpenWebCamModal ? (
        <WebCamModal
          isOpenWebCamModal={isOpenWebCamModal}
          setIsOpenWebCamModal={setIsOpenWebCamModal}
          singleItemImages={singleItemImages}
          setSingleItemImages={setSingleItemImages}
        />
      ) : null}

      {previewImageIndex !== null && previewImageIndex !== undefined ? (
        <LightboxModal
          images={singleItemImages}
          mainSrc={singleItemImages[previewImageIndex]?.data}
          photoIndex={previewImageIndex}
          setPhotoIndex={(index) =>
            index !== null && index !== undefined && setPreviewImageIndex(index)
          }
          isOpen={previewImageIndex !== null && previewImageIndex !== undefined}
          onCloseRequest={() => setPreviewImageIndex(null)}
        />
      ) : null}

      <input
        type="file"
        hidden
        ref={fileInput}
        accept=".jpg,.png,.jpeg,.gif,.webp,.bmp,.heif"
        onChange={handleFileUploads}
      />
    </Box>
  );
}

const CategoriesContent = (props) => {
  const {
    activeStep,
    errorMessage,
    categories,
    goToCatogory,
    categoryNote,
    chceckSelection,
    questionList,
    fileInput,
    setIsOpenWebCamModal,
    singleItemImages,
    previewImage,
    handleRemoveImage,
    checkSelecteditems,
    handleSelectCard,
    setCategoryNote,
    handleItemComment,
    handleNextItem,
    handleBackbuttonClick,
    itemComment,
    reportsInventories,
    scoutInventoryComment,
    setScoutInventoryComment,
    scoutInventoryReading,
    setScoutInventoryReading,
  } = props;

  const [tempScoutInventoryReading, setTempScoutInventoryReading] = useState(
    scoutInventoryReading
  );
  const [tempScoutInventoryComment, setTempScoutInventoryComment] = useState(
    scoutInventoryComment
  );

  useEffect(() => {
    if (activeStep === -2) {
      setTempScoutInventoryReading(scoutInventoryReading);
      setTempScoutInventoryComment(scoutInventoryComment);
    }
    // eslint-disable-next-line
  }, [activeStep]);

  const handleInventoryReadingChange = (e, inventoryId) => {
    setTempScoutInventoryReading({
      ...tempScoutInventoryReading,
      [inventoryId]: {
        scout_inventory_id: inventoryId,
        reading: e.target?.value ?? '',
      },
    });
  };

  const handleInventoryReadingSubmit = () => {
    setScoutInventoryComment(tempScoutInventoryComment);
    setScoutInventoryReading({ ...tempScoutInventoryReading });
    handleNextItem();
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // activeStep = 0 => show the "categories listing" section ...
  if (activeStep === 0) {
    return (
      <Stack sx={{ my: 1 }} spacing={3}>
        {errorMessage ? (
          <Typography color="error">
            Please add note or select any other options
          </Typography>
        ) : null}

        {categories?.length > 0 ? (
          <>
            <Button
              variant="contained"
              sx={{ p: 2 }}
              onClick={() => goToCatogory(-1)}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Box sx={{ minWidth: '92%', pl: 2 }}>
                  <Typography variant="subtitle2">Add note</Typography>
                </Box>

                {categoryNote?.length ? <OutlineCheckOneIcon /> : null}
              </Stack>
            </Button>

            {reportsInventories?.length > 0 &&
              <Button
                variant="contained"
                sx={{ p: 2 }}
                onClick={() => goToCatogory(-2)}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ minWidth: '92%', pl: 2 }}>
                    <Typography variant="subtitle2">Inventory reading</Typography>
                  </Box>

                  {tempScoutInventoryReading[Object.keys(tempScoutInventoryReading)[0]]?.reading?.length || scoutInventoryReading[Object.keys(scoutInventoryReading)[0]]?.reading?.length || tempScoutInventoryComment?.length ? (
                    <OutlineCheckOneIcon />
                  ) : null}
                </Stack>
              </Button>
            }

            {categories?.map((cat, index) => {
              return (
                <Button
                  key={`category-button-${index}`}
                  variant="contained"
                  sx={{ p: 2 }}
                  onClick={() => goToCatogory(cat.itemId)}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Box sx={{ minWidth: '92%', pl: 2 }}>
                      <Typography variant="subtitle2">
                        {cat?.category ? cat?.category : ''}
                      </Typography>
                    </Box>

                    {chceckSelection(cat) ? <OutlineCheckOneIcon /> : null}
                  </Stack>
                </Button>
              );
            })}
          </>
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mx: 'auto' }}
          >
            Sorry, no matching record found
          </Typography>
        )}
      </Stack>
    );
  }

  // activeStep = -2 => show the "inventory reading" section ...
  if (activeStep === -2) {
    return (
      <Box>
        <Typography variant="subtitle2" sx={{ mb: 2, ml: 1 }}>
          Inventory reading
        </Typography>

        <Stack spacing={2} sx={{ mb: 1.5 }}>
          {reportsInventories?.map((inventory) => {
            return (
              <Stack
                key={`scout-inventory-list-${inventory.id}`}
                sx={{
                  backgroundColor: '#d4d4d47d',
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <Stack direction="row">
                  <Stack sx={{ width: '100%' }}>
                    <Typography component="div" sx={{ display: 'flex' }}>
                      <Typography variant="caption" color="text.secondary">
                        Inventory name:
                      </Typography>

                      <Typography variant="caption" color="text.primary">
                        &nbsp;
                        {inventory?.name ?? '-'}
                      </Typography>
                    </Typography>

                    <Typography component="div" sx={{ display: 'flex' }}>
                      <Typography variant="caption" color="text.secondary">
                        Inventory type:
                      </Typography>

                      <Typography variant="caption" color="text.primary">
                        &nbsp;
                        {inventory?.inventory_type_name ?? '-'}
                      </Typography>
                    </Typography>

                    <Typography component="div" sx={{ display: 'flex' }}>
                      <Typography variant="caption" color="text.secondary">
                        Inventory type item:
                      </Typography>

                      <Typography variant="caption" color="text.primary">
                        &nbsp;
                        {inventory?.has_inventory_type_item?.name ?? '-'}
                      </Typography>
                    </Typography>

                    <Typography component="div" sx={{ display: 'flex' }}>
                      <Typography variant="caption" color="text.secondary">
                        Expiration date:
                      </Typography>

                      <Typography variant="caption" color="text.primary">
                        &nbsp;
                        {moment(inventory?.expiration_date)?.format(
                          'DD MMM YYYY'
                        ) ?? '-'}
                      </Typography>
                    </Typography>
                  </Stack>

                  <Stack>
                    <IconButton
                      size="small"
                      sx={{ maxWidth: '24px !important', minWidth: '20px' }}
                      onClick={(_) =>
                        navigateMapTo(
                          inventory?.geo_locations?.latitude ?? '',
                          inventory?.geo_locations?.longitude ?? ''
                        )
                      }
                    >
                      <LinkIcon />
                    </IconButton>
                  </Stack>
                </Stack>

                <TextField
                  id={`inventory-reading-input-${inventory.id}`}
                  label="Reading"
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 1.5 }}
                  size="small"
                  value={tempScoutInventoryReading[inventory.id]?.reading ?? ''}
                  onChange={(e) =>
                    handleInventoryReadingChange(e, inventory.id)
                  }
                />
              </Stack>
            );
          })}

          <TextField
            id="inventory-reading-global-comment"
            label="Comment"
            variant="outlined"
            fullWidth
            sx={{ mt: 1.5 }}
            size="small"
            multiline
            maxRows={3}
            value={tempScoutInventoryComment}
            onChange={(e) => setTempScoutInventoryComment(e.target.value)}
          />
        </Stack>

        <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
          <Button
            variant="contained"
            color="inherit"
            size="large"
            fullWidth
            onClick={handleBackbuttonClick}
            sx={{ backgroundColor: '#E8E8E8 !important' }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            onClick={handleInventoryReadingSubmit}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    );
  }

  // activeStep = -1 => show the "Add Note" details section ...
  // activeStep > 0 => show the respective category details section ...
  return (
    <Box>
      {activeStep === -1 ? (
        <Typography variant="subtitle2" sx={{ mb: 2, ml: 1 }}>
          Add note
        </Typography>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 2, ml: 1 }}
        >
          <Typography variant="subtitle2">
            {questionList[activeStep - 1]?.categoryName}
          </Typography>

          <Stack direction="row" gap={1}>
            <IconButton onClick={() => fileInput.current?.click()}>
              <ImageIcon />
            </IconButton>

            <IconButton onClick={() => setIsOpenWebCamModal(true)}>
              <CameraFillIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}

      {singleItemImages?.length ? (
        <Container sx={{ px: 0 }}>
          <Slider
            {...settings}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {singleItemImages.map((data, index) => (
              <Box
                sx={{ px: 1, position: 'relative' }}
                key={`carousel-item-img-${index}`}
              >
                <Image
                  alt="preview"
                  src={data?.data}
                  ratio="1/1"
                  onClick={() => previewImage(index)}
                  sx={{ borderRadius: 1.25, overflow: 'hidden' }}
                />
                <IconButton
                  size="small"
                  onClick={() => handleRemoveImage(index)}
                  sx={{
                    top: 2,
                    p: '0px 4px',
                    right: 12,
                    position: 'absolute',
                    color: 'common.white',
                    bgcolor: '#161c24b8',
                    '&:hover': {
                      bgcolor: '#161c247a',
                    },
                  }}
                >
                  <CloseFillIcon />
                </IconButton>
              </Box>
            ))}
          </Slider>
        </Container>
      ) : null}

      <Stack
        spacing={2}
        sx={{
          my: 1,
          overflow: 'auto',
          display: 'block',
        }}
      >
        {questionList[activeStep - 1]?.itemOptions?.map((que, index) => (
          <Card
            sx={{
              p: 2,
              px: 2,
              boxShadow: 'none',
              flexGrow: 1,
              display: 'flex',
              borderRadius: 1,
              overflow: 'auto',
              backgroundColor: () => checkSelecteditems(que.id),
            }}
            key={`item-${index}`}
            onClick={(event) => {
              event.stopPropagation();
              handleSelectCard(event, que.id);
            }}
          >
            <Typography variant="body1" sx={{ ml: 1 }}>
              {que?.label ? que.label : ''}
            </Typography>
          </Card>
        ))}

        <TextField
          id="item-comment-text-field"
          label="Comment"
          variant="outlined"
          value={activeStep === -1 ? categoryNote : itemComment}
          multiline
          fullWidth
          sx={{ mt: 2 }}
          rows={activeStep === -1 ? 12 : 2}
          onChange={(e) => {
            if (activeStep === -1) {
              setCategoryNote(e.target.value);
            } else {
              handleItemComment(e);
            }
          }}
        />
      </Stack>

      <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          size="large"
          fullWidth
          onClick={handleBackbuttonClick}
          sx={{ backgroundColor: '#E8E8E8 !important' }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          onClick={() => handleNextItem()}
        >
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

const WebCamModal = (props) => {
  const {
    isOpenWebCamModal,
    setIsOpenWebCamModal,
    singleItemImages,
    setSingleItemImages,
  } = props;

  const [capturedImages, setCapturedImages] = useState(singleItemImages ?? []);
  const webcamRef = React.useRef(null);

  const [facingMode, setFacingMode] = useState('environment');

  const generateFileName = () => {
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10000);
    const fileName = `${timestamp}_${randomNumber}.webp`;
    return fileName;
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const fileName = generateFileName();
    setCapturedImages([{ name: fileName, data: imageSrc }, ...capturedImages]);
  };

  const handleCameraSwitch = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
  };

  const removeImage = (index) => {
    const filteredImages = capturedImages?.filter((el, ind) => index !== ind);
    setCapturedImages(filteredImages);
  };

  const handleSubmit = () => {
    setSingleItemImages(capturedImages);
    setIsOpenWebCamModal(false);
  };

  return (
    <Dialog
      open={isOpenWebCamModal}
      onClose={() => setIsOpenWebCamModal(false)}
      fullWidth
      maxWidth={'sm'}
      sx={{ minHeight: '90%' }}
    >
      <DialogContent sx={{ p: 2, overflow: 'auto' }}>
        <Webcam
          audio={false}
          ref={webcamRef}
          width="100%"
          height="75%"
          videoConstraints={{ facingMode }}
          style={{ borderRadius: 8, overflow: 'hidden' }}
        />

        <Stack direction="row" justifyContent="space-around" sx={{ mb: 1 }}>
          <IconButton onClick={handleCameraSwitch}>
            <RotateIcon />
          </IconButton>

          <IconButton onClick={captureImage}>
            <Box
              sx={{
                height: 42,
                width: 42,
                backgroundColor: '#ff3535',
                borderRadius: '50%',
              }}
            />
          </IconButton>

          <Box sx={{ width: 20, color: 'transparent' }} />
        </Stack>

        <Grid container spacing={2}>
          {capturedImages?.map((img, index) => (
            <Grid key={`captured-image-${index}`} item xs={3}>
              <ListItem
                sx={{
                  p: 0,
                  m: 0.5,
                  borderRadius: 1.25,
                  overflow: 'hidden',
                  position: 'relative',
                  display: 'inline-flex',
                  border: `solid 1px #FEFEFE`,
                }}
              >
                <Image alt="preview" src={img?.data} ratio="1/1" />
                <IconButton
                  size="small"
                  onClick={() => removeImage(index)}
                  sx={{
                    top: 6,
                    p: '0px 4px',
                    right: 6,
                    position: 'absolute',
                    color: 'common.white',
                    bgcolor: '#161c24b8',
                    '&:hover': {
                      bgcolor: '#161c247a',
                    },
                  }}
                >
                  <CloseFillIcon />
                </IconButton>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions sx={{ p: '16px !important' }}>
        <Button
          color="inherit"
          onClick={() => {
            setCapturedImages([]);
            setIsOpenWebCamModal(false);
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton variant="outline-light" onClick={onClick} size="small">
      <RoundedNextIcon sx={{ color: '#000' }} />
    </IconButton>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton variant="outline-light" onClick={onClick} size="small">
      <RoundedPreviousIcon sx={{ color: '#000' }} />
    </IconButton>
  );
};

const LocationPinComponent = (props) => {
  const { locationPins, setLocationPins } = props;

  const { coords, positionError } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  const handleAddLocation = () => {
    if (positionError) {
      // handle location permission error
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position?.coords;

        const newLocationPinData = {
          id: `new-${locationPins?.length ?? 1}`,
          latitude,
          longitude,
        };

        setLocationPins([...locationPins, newLocationPinData]);
      });
    } else {
      const { latitude, longitude } = coords;

      const newLocationPinData = {
        id: `new-${locationPins?.length ?? 1}`,
        latitude,
        longitude,
      };

      setLocationPins([...locationPins, newLocationPinData]);
    }
  };

  const handleDeleteLocation = (pinId) => {
    const filteredlocations = locationPins?.filter((el) => el.id !== pinId);
    setLocationPins(filteredlocations);
  };

  return (
    <>
      {positionError && !coords ? (
        <Alert severity="error" sx={{ py: 0 }}>
          Please allow location access from settings
        </Alert>
      ) : null}

      {locationPins?.map((pin, index) => {
        return (
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: 1,
              px: 2,
              py: 1,
            }}
            key={index}
          >
            <Stack direction="row" spacing={1}>
              <LocationOnIcon sx={{ display: 'flex', alignItems: 'center' }} />
              <Box>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Latitude: {pin.latitude}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Longitude: {pin.longitude}
                </Typography>
              </Box>
            </Stack>

            <Button
              size="small"
              color="error"
              onClick={(_) => handleDeleteLocation(pin.id)}
            >
              Delete
            </Button>
          </Card>
        );
      })}
    </>
  );
};
