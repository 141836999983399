//

import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// indexDB
import { updateIndexDBRecord } from '../../indexDB/indexdbUtilsFunctions';
import { INDEX_DB_CONFIG } from '../../indexDB/configDB';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  customers: {},
  error: null,
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(customers) {
      customers.isLoading = true;
      customers.customers = {};
    },

    // HAS ERROR
    hasError(customers, action) {
      customers.isLoading = false;
      customers.error = action.payload;
      customers.customers = {};
    },

    // GET
    getCustomersSuccess(customers, action) {
      customers.isLoading = false;
      customers.customers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPWACustomers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/customers');
      if (response?.data?.status === 1) {
        if (Array.isArray(response.data?.data?.customers)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.customers.storeObject,
            response.data.data.customers
          );
        } else {
          console.error('Invalid API response of v1/customers => customer');
        }
        if (Array.isArray(response.data?.data?.prospect_customers)) {
          await updateIndexDBRecord(
            INDEX_DB_CONFIG.prospectCustomers.storeObject,
            response.data.data.prospect_customers
          );
        } else {
          console.error(
            'Invalid API response of v1/customers => prospect customers'
          );
        }

        dispatch(slice.actions.getCustomersSuccess(response.data.data));
      } else {
        dispatch(slice.actions.hasError(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
